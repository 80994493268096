import React, { Component, Fragment } from 'react';
import { render} from 'react-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import Header from "components/Headers/Header.js";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import axios from "axios"
import { Link } from 'react-router-dom';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


class AddBlog extends Component {
    constructor(props) {
        super(props);
        this.state = { visible: false,listings:[],approved:null,records:[],DeleteModal:false,blogid:"",loading:true};
        this.columns = [
            {
                key: "title",
                text: "Title",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "category",
                text: "Category",
                className: "address",
                align: "left",
                sortable: true
            },
            {
                key: "meta_title",
                text: "Meta Title",
                className: "approved",
                sortable: true
            },
            {
                key: "meta_keyword",
                text: "Meta Keyword",
                className: "approved",
                sortable: true
            },
            {
                key: "status",
                text: "Status",
                className: "approved",
                sortable: true
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => { 
                    return (
                        <>
                        <Fragment>
                            <Link to={`/admin/editblog/${record.id}`}
                                className="btn btn-danger btn-sm" >
                                <i className="fa fa-pencil-square-o"></i>
                            </Link>
                            <button onClick={() => this.showDeleteModal(record)}
                                    className="btn btn-danger btn-sm" >
                              <i class="fa fa-trash" aria-hidden="true"></i>

                                </button>
                        </Fragment>
                        </>
                    );
                }
            }
        ];
        

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            button: {
                excel: true,
                csv: true
            }
        }
        
        // this.extraButtons =[
        //     {
        //         className:"btn btn-primary buttons-pdf",
        //         title:"Export TEst",
        //         children:[
        //             <span>
        //                 <i className="fa fa-user fa fa-print" aria-hidden="true"></i>
        //             </span>
        //         ],
        //         onClick:(event)=>{
        //             console.log(event);
        //         },
        //     },
        //     {
        //         className:"btn btn-primary buttons-pdf",
        //         title:"Export TEst",
        //         children:[
        //             <span>
        //             <i className="fa fa-user fa fa-print" aria-hidden="true"></i>
        //             </span>
        //         ],
        //         onClick:(event)=>{
        //             console.log(event);
        //         },
        //         onDoubleClick:(event)=>{
        //             console.log("doubleClick")
        //         }
        //     },
        // ]
    }
    componentDidMount(){
        if(!localStorage.getItem("admin_token")){
            this.props.history.push("/auth/login")
            }else{
              this.getListingData()
            }
   }
   getListingData=async ()=>{
       this.setState({
           loading:false
       })
    await axios.get(`${process.env.REACT_APP_API}/api/auth/adminblogs`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_token")}`
      }
      }).then((res)=>{
        if(res.data.Error==false){
            this.setState({
                listings:res.data.data,
            })
            this.setTableData(this.state.listings)
        } 
        else if(res.data.token==false){
            localStorage.removeItem("admin_token")
            this.props.history.push("/auth/login")
          }
          this.setState({
            loading:true
        })   
        })
   }
   setTableData=(data)=>{
    console.log("faisal",  this.state.category)
    let data1=[]
    data && data.map((dat,i)=>{
     return data1.push({
         
             "id": dat._id,
             "title": dat.Title,
             "category": dat.Category.Name,             
             "meta_title": dat.Meta_title,
             "meta_keyword": dat.Meta_keyword,
             "status": dat.Active ? "Active":"Deactive"     
      
             
      })
    })
    this.setState({
        records:data1
    })

}
    editRecord(record) {
        console.log("Edit Record", record);
    }

    deleteRecord(record) {
        console.log("Delete Record", record);
    }
    showDeleteModal=(record)=>{
        this.setState({
            blogid:record.id,
            DeleteModal:true
        })
    }
    deleteData=async()=>{
        let data={
             id:this.state.blogid

        }
        await axios.post(`${process.env.REACT_APP_API}/api/auth/admindeleteblog`,data,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("admin_token")}`
          }
          }).then((res) => {
            if (res.data.success == true) {
                this.setState({
                    DeleteModal:false
                })
              this.getListingData()
            }
            else if(res.data.token==false){
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
              }
        })

    }
    show() {
        this.setState({ visible: true });
      }
     
      hide() {
        this.setState({ visible: false });
      }
  
    render() {
        return (

                <>
            <Header />
                <div className="mt--7 container-fluid">
                    <div className="shadow card">
                        <div className="border-0 card-header d-flex align-items-center justify-content-between">
                            <h3 class="mb-0">Blogs</h3>
                            <div className="add_blog">
                                <Link to='/admin/addblog' className="btn btn-primary">Add Blog</Link>
                            </div>
                        </div>
                        {this.state.loading ?
                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            extraButtons={this.extraButtons}
                        />
                                         :              
                                      <div className="loader">
                                       <Loader
                                       type="Puff"
                                       color="#00BFFF"
                                       height={100}
                                       width={100}
                                     />
                                     </div>
                        }
                    </div>
                </div>
                <Rodal visible={this.state.visible} onClose={this.hide.bind(this)} width="900" height="600" >
                    <div className="header_view_popup">Edit Blog</div>
                    
                </Rodal>
                {
                    this.state.DeleteModal ?
                        <Rodal visible={this.state.DeleteModal} onClose={()=>this.setState({DeleteModal:false})} width="600" height="300" >
                            <div className="header_view_popup">Delete Confirmation</div>
                            <div className="main_listing_view">
                                <div className="listing_goes mb-4">
                                Are You Sure Want To Delete? 
                                </div>
                                <button onClick={this.deleteData} type="submit" className="btn btn-primary mt-4">Submit</button>
                            </div>
                        </Rodal>
                        :
                        ""

                }
            </>
                

        )
    }
}

export default AddBlog;