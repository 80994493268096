import Header from 'components/Headers/Header'
import React, { Component } from 'react'
import axios from "axios"


class AddCategory extends Component {

    state = {
        cat: "",
    }


    addCategory = async () => {

        if (this.state.cat) {
            let data = {
                Name: this.state.cat
            }
            await axios.post(`${process.env.REACT_APP_API}/api/auth/addblogcategory`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("admin_token")}`
                }
            }).then((res) => {
                if (res.data.Error == false) {
                    this.setState({
                        cat: ""
                    })
                    this.props.history.push("/admin/categories")
                }
                else if (res.data.token == false) {
                    localStorage.removeItem("admin_token")
                    this.props.history.push("/auth/login")
                }
            })
        }

    }




    render() {
        return (
            <>


                <Header />
                <div className="addtags">
                    <div className="mt--7 container-fluid">
                        <div className="shadow card">
                            <div className="border-0 card-header">
                                <h3 className="mb-0">Add Category</h3>
                            </div>

                            <div className="list_add_cate">
                                <label className="form-control-label">Category Name</label>
                                <input placeholder="Enter Category" value={this.state.cat} className="form-control-alternative form-control" onChange={(e) => { this.setState({ cat: e.target.value }) }} />
                            </div>
                            <div className="add_category_div">
                                <div className="main_add_btn text-center">
                                    <button onClick={this.addCategory} type="button" className="btn btn-primary mb-3">Add Category</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default AddCategory;