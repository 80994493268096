import React, { Component, Fragment, useEffect, useState } from 'react';
import { render } from 'react-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import DataTable, { TableColumn, TableStyles } from "react-data-table-component";
import Header from "components/Headers/Header.js";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import axios from "axios"
import { Link } from 'react-router-dom';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import movies from "./data";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import QueryActionModal from './queryActionModal';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import Alert from './alert';
import SendQueryDetailsModal from './querySentVendorDetails';


// import SortIcon from "@material-ui/icons/ArrowDownward";

// const config = {
//     page_size: 10,
//     length_menu: [10, 20, 50],
//     button: {
//         excel: true,
//         csv: true
//     }
// }

const Query = (props) => {
    const columns = [
        {
            id: 1,
            name: "Event Date",
            selector: (row) => row.event_date,
            sortable: true,
            reorder: true
        },
        {
            id: 2,
            name: "Event Time",
            selector: (row) => row.event_time,
            sortable: true,
            reorder: true
        },
        {
            id: 3,
            name: "Budget",
            selector: (row) => row.event_location,
            sortable: true,
            // right: true,
            reorder: true
        },
        {
            id: 4,
            name: "Event Location",
            selector: (row) => row.event_location,
            sortable: true,
            // right: true,
            reorder: true
        },
        {
            id: 5,
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            // right: true,
            reorder: true
        },
        // {
        //     id: 6,
        //     name: "mobile",
        //     selector: (row) => row.mobile,
        //     sortable: true,
        //     // right: true,
        //     reorder: true
        // },
        {
            id: 7,
            name: "Message",
            selector: (row) => row.message,
            sortable: true,
            // right: true,
            reorder: true
        },
        
        {
            id: 9,
            name: "Details",
            // selector: (row) => row.message,
            sortable: true,
            right: true,
            reorder: true,
            cell: record => {
                return (
                    <>
                        <Fragment>
                           
                                <button onClick={(e) => sentQueryModalDetailsToggle(record)}
                                    className="btn btn-info btn-sm" >
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                </button>
                        </Fragment>
                    </>
                );
            }
        },
        {
            id: 7,
            name: "Action",
            // selector: (row) => row.message,
            sortable: true,
            right: true,
            reorder: true,
            cell: record => {
                return (
                    <>
                        <Fragment>
                            {activeTab == 1 ?
                                <button onClick={(e) => clickedBtn(record)}
                                    className="btn btn-success btn-sm" >
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                                :
                                <button onClick={(e) => reActionFun(record)}
                                    className="btn btn-danger btn-sm" >
                                    <i class="fa fa-trash-restore" aria-hidden="true"></i>
                                </button>
                                
                            }
                        </Fragment>
                    </>
                );
            }
        }
    ];
    const api = new API()
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState('1')
    const [selectedData, setSelectedData] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [visible, setVisible] = useState(false)
    const [allQueryData, setAllQueryData] = useState([])
    const [actionStatus, setActionStatus] = useState("0")
    const [listings, setListings] = useState([])
    const [isAlert, setIsAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [pendingQuery, setPendingQuery] = useState(0)
    const [actionDoneQuery, setActionDoneQuery] = useState(0)
    const [showSentQueryDetailsModal, setShowSentQueryDetailsModal] = useState(false)

    useEffect(() => {
        if (!localStorage.getItem("admin_token")) {
            this.props.history.push("/auth/login")
        } else {
            getQuery(actionStatus)
            getListingData()
        }
    }, [])

    useEffect(() => {
        getQuery(actionStatus)
    }, [actionStatus])


    const leadOnlickFun = (e) => {
        // console.log(e)
    }
    const handleChangeSelectRow = (state) => {  // not using this, it will use with multiselect 
        setSelectedData(state.selectedRows);
    };
    const extraButtons = () => {
        // console.log("hit")
    }
    const toggle = () => {
        setVisible(!visible)
    }
    const deleteSelectedData = (deleteRecord) => {
        setSelectedData((data) => {
            return [...data.filter(val => deleteRecord.id != val.id)]
        })
    }
    const getQuery = async (actionStatus) => {
        let data = {
            action_status: actionStatus
        }
        let result = await api.post(config.getQuery, data)
        if (result && result.code == 200) {
            setAllQueryData(result.data)
            actionStatus == 0 ? setPendingQuery(result.data.length) : setActionDoneQuery(result.data.length) 
            
        } else {

        }
    }
    const actionQuery = async (Data) => {
        let data = {
            selectedActionQuery: JSON.stringify(Data.selectedData),
            selectedClient: JSON.stringify(Data.selectedClient),
            message: JSON.stringify(Data.mobileMessage)
        }

        // return
        let result = await api.post(config.queryAction, data)
        if (result && result.code == 200) {
            setAlertMessage("Send query successfully to selected client");
            isAlertToggle()
            getQuery(actionStatus)
            toggle()
        } else {
            setAlertMessage("Something went wrong");
            isAlertToggle()
        }
    }
    const getListingData = async () => {
        let data = {

        }
        let result = await api.post(config.getAllListing, data)
        if (result && result.code == 200) {
            setListings(result.data)
        } else {

        }
    }

    const clickedBtn = (record) => {
        setSelectedData([record]);
        toggle()
    }
    const reActionFun = async (record) => {
        let data = {
            status: "0",
            query: JSON.stringify(record)
        }
        let result = await api.post(config.actionStatusChange, data)
        if (result && result.code == 200) {
            setAlertMessage("Successfully move to pending query");
            isAlertToggle()
            getQuery(actionStatus)
        } else {
            setAlertMessage("Unable to complete action");
            isAlertToggle()
        }
    }


    const isAlertToggleClose = () => {
        setIsAlert(false);
    }
    const isAlertToggle = (msg) => {
        setIsAlert(!isAlert);
    };
    const sentQueryModalDetailsToggle = (query_details) => {
        setSelectedData([query_details]);
        setShowSentQueryDetailsModal(!showSentQueryDetailsModal)
    }
    return (

        <>
            <Header />
            <div className="mt--7 container-fluid">
                <div className="shadow card">
                    <div className="border-0 card-header d-flex align-items-center justify-content-between">
                        <h3 class="mb-0">User Query</h3>
                        <div className="add_blog">
                            <div className='row'>
                                <div className='col-md-8'>
                                    <input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} type="text" className="form-control" placeholder="Search.." />
                                </div>
                                <div className='col-md-4'>
                                    <Link to='/admin/addblog' className="btn btn-primary">Query</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Nav tabs>
                            <NavItem>
                                <NavLink style={{ cursor: "pointer", position: "relative" }}
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => {
                                        setActiveTab('1')
                                        setSearchTerm("")
                                        setActionStatus("0")
                                    }}
                                >
                                    <span style={{ marginRight: "20px" }}>Pending Query</span><span style={{ marginLeft: "-20px" }} class="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-info">
                                        {pendingQuery}
                                        {/* <span class="visually-hidden">unread messages</span> */}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink style={{ cursor: "pointer", position: "relative" }}
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => {
                                        setActiveTab('2')
                                        setSearchTerm("")
                                        setActionStatus("1")
                                    }}
                                >
                                    Completed Query<span class="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-primary">
                                        {actionDoneQuery}
                                        {/* <span class="visually-hidden">unread messages</span> */}
                                    </span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        <Card body>
                                            <CardTitle>New lead from client</CardTitle>
                                            <CardText>

                                                {!loading ?
                                                    <DataTable
                                                        // title="Query"
                                                        // config={config}
                                                        columns={columns.filter(val=>val.id != 9)}
                                                        data={allQueryData.filter((item) => {
                                                            if (searchTerm === "") {
                                                                return item;
                                                            } else if (
                                                                item.name.toLowerCase().includes(searchTerm.toLowerCase())
                                                            ) {
                                                                return item;
                                                            }
                                                        })}
                                                        defaultSortFieldId={1}
                                                        // sortIcon={<SortIcon />}
                                                        pagination
                                                        // selectableRows
                                                        extraButtons={extraButtons}
                                                        onSelectedRowsChange={handleChangeSelectRow}
                                                    />
                                                    :
                                                    <div className="loader">
                                                        <Loader
                                                            type="Puff"
                                                            color="#00BFFF"
                                                            height={100}
                                                            width={100}
                                                        />
                                                    </div>
                                                }
                                            </CardText>
                                            <Button onClick={toggle}>Send Lead to Client</Button>
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="12">
                                        <Card body>
                                            <CardTitle>Action Done</CardTitle>
                                            <CardText>

                                                {!loading ?
                                                    <DataTable
                                                        // title="Query"
                                                        columns={columns}
                                                        data={allQueryData}
                                                        defaultSortFieldId={1}
                                                        // sortIcon={<SortIcon />}
                                                        pagination
                                                        // selectableRows
                                                    />
                                                    :
                                                    <div className="loader">
                                                        <Loader
                                                            type="Puff"
                                                            color="#00BFFF"
                                                            height={100}
                                                            width={100}
                                                        />
                                                    </div>
                                                }
                                            </CardText>
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </div>

                </div>
            </div>

            <QueryActionModal
                actionQuery={actionQuery}
                selectedData={selectedData}
                deleteSelectedData={deleteSelectedData}
                toggle={toggle}
                visible={visible}
                listings={listings} />
            <Alert
                isAlert={isAlert}
                isAlertToggle={isAlertToggle}
                alertMessage={alertMessage}
                isAlertToggleClose={isAlertToggleClose}
            />
            <SendQueryDetailsModal 
            toggle={sentQueryModalDetailsToggle} visible={showSentQueryDetailsModal} selectedData={selectedData}/>
        </>

    )
}

export default Query;