import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';

export default function TextEditor(props) {
    let { setEmailMessage } = props
    // let _contentState = ContentState.createFromText('Sample content state');
    // const raw = convertToRaw(_contentState)
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()

    );
    useEffect(() => {
        let emailMessage = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setEmailMessage(emailMessage)
    }, [editorState]);

    return (
        <div>
            <div style={{ border: "1px solid black", padding: '2px', minHeight: '200px' }}>
                <Editor
                    // defaultContentState={editorState}
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                />
            </div>
        </div>
    );
}