import React, { Component, Fragment, useEffect, useState } from 'react';
import { render } from 'react-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import DataTable, { TableColumn, TableStyles } from "react-data-table-component";
import Header from "components/Headers/Header.js";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import axios from "axios"
import { Link } from 'react-router-dom';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TextEditor from './textEditor';
import Alert from './alert';
import movies from "./data";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
        excel: true,
        csv: true
    }
}
const columns = [
    {
        id: 1,
        name: "Client Name",
        selector: (row) => row.vendor_id?.name,
        sortable: true,
        // right: true,
        reorder: true
    },
    {
        id: 2,
        name: "Client Email",
        selector: (row) => row.vendor_id?.email,
        sortable: true,
        // right: true,
        reorder: true
    },
    {
        id: 3,
        name: "Name",
        selector: (row) => row.Name,
        sortable: true,
        reorder: true
    },
    {
        id: 4,
        name: "Mobile_Number",
        selector: (row) => row.Mobile_Number,
        sortable: true,
        // right: true,
        reorder: true
    },
    {
        id: 5,
        name: "Category",
        selector: (row) => row.Category,
        sortable: true,
        reorder: true
    },
    {
        id: 6,
        name: "Payment",
        selector: (row) => row.Payment,
        sortable: true,
        // right: true,
        reorder: true
    },
    {
        id: 7,
        name: "Profile_Pic",
        selector: (row) => row.Profile_Pic,
        sortable: true,
        // right: true,
        reorder: true
    },
    // {
    //     id: 6,
    //     name: "mobile",
    //     selector: (row) => row.mobile,
    //     sortable: true,
    //     // right: true,
    //     reorder: true
    // },
    {
        id: 8,
        name: "Slug",
        selector: (row) => row.Slug,
        sortable: true,
        // right: true,
        reorder: true
    },
    {
        id: 9,
        name: "Status",
        selector: (row) => row.Status,
        sortable: true,
        // right: true,
        reorder: true
    },
    {
        id: 10,
        name: "Sub_Category",
        selector: (row) => row.Sub_Category,
        sortable: true,
        // right: true,
        reorder: true
    },






];
const QueryActionModal = (props) => {
    const { toggle, visible, selectedData, deleteSelectedData, actionQuery,
        listings } = props
    const [filteredText, setFilteredText] = useState("")
    const [isAlert, setIsAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [emailMessage, setEmailMessage] = useState("email for pending documents")

    const [selectedClient, setSelectedClient] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [mobileMessage, setMobileMessage] = useState('')

    const isAlertToggleClose = () => {
        setIsAlert(false);
    }
    const isAlertToggle = (msg) => {
        setIsAlert(!isAlert);

    };
    const handleChangeSelectRow = (state) => {
        setSelectedClient(state.selectedRows);
        // console.log(selectedClient);
    };
    const extraButtons = () => {
        // console.log("hit")
    }
    const sendQueryActionFun = () => {
        if(!mobileMessage){
            toast.warning("Please enter mobile message")
            return
        }
        actionQuery({ selectedData, selectedClient, emailMessage, mobileMessage })
        // setAlertMessage("Are you sure");
        // isAlertToggle()
    }
    return (
        <div>
            <Modal
                isOpen={visible}
                toggle={toggle}
                size="xl"
                // style={{maxWidth: '700px', width: '100%'}}
                className="lg">
                <ModalHeader className='modal_header_query' toggle={toggle} charCode={<i className='fa fa-close '></i>}>

                    <h3>Send query</h3>
                </ModalHeader>
                <ModalBody>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">event_date</th>
                                <th scope="col">event_time</th>
                                <th scope="col">event_location</th>
                                <th scope="col">name</th>
                                <th scope="col">mobile</th>
                                <th scope="col">message</th>
                                <th scope="col">Action</th>

                            </tr>
                        </thead>
                        <tbody>
                            {selectedData?.map((val, index) => (
                                <tr>
                                    <th scope="row">1</th>
                                    <td>{val.event_date}</td>
                                    <td>{val.event_time}</td>
                                    <td>{val.event_location}</td>
                                    <td>{val.name}</td>
                                    <td>{val.mobile}</td>
                                    <td>{val.message}</td>
                                    <td><button onClick={() => deleteSelectedData(val)} className='btn btn-danger btn-sm'><i className='fa fa-trash'></i></button></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                    <div className='row'>
                        <div className='col-md-6'>
                        </div>
                        <div className='col-md-6'>
                            <div className="search-box-size">
                                <form>
                                    <div className="input-group">
                                        <input type="text" className="form-control" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search By Category.." />
                                        <div className="input-group-btn ">
                                            <button className="btn btn-default btn btn-secondary" type="submit">
                                                <i className="glyphicon glyphicon-search  fa fa-search text-black"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* <Editor tools={[[Bold, Italic, Underline, Strikethrough], 
            [Subscript, Superscript], [AlignLeft, AlignCenter, AlignRight, AlignJustify], 
            [Indent, Outdent], [OrderedList, UnorderedList], FontSize, FontName, FormatBlock, 
            [Undo, Redo], [Link, Unlink, InsertImage, ViewHtml], [InsertTable], 
            [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter], 
            [DeleteRow, DeleteColumn, DeleteTable], [MergeCells, SplitCell]]} 
            contentStyle={{
              height: 230
            }} defaultContent={emailMessage} />; */}
                        <div className='container'>
                            <TextEditor
                                setEmailMessage={setEmailMessage}
                            />
                            <form>

                                <div class="form-group">
                                    <label for="exampleInputEmail1">Enter your message</label>
                                    <textarea type="email" value={mobileMessage} onChange={(e)=>setMobileMessage(e.target.value)} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter message" />
                                    <small id="emailHelp" class="form-text text-muted">Write mobile message.</small>
                                </div>
                            </form>
                            {selectedData?.length > 0 ?
                                <DataTable
                                    // title="Query"
                                    config={config}
                                    columns={columns}
                                    data={listings.filter((item) => {
                                        if (searchTerm === "") {
                                            return item;
                                        } else if (
                                            item.Category.toLowerCase().includes(searchTerm.toLowerCase())
                                        ) {
                                            return item;
                                        }
                                    })}
                                    defaultSortFieldId={1}
                                    // sortIcon={<SortIcon />}
                                    pagination
                                    selectableRows
                                    extraButtons={extraButtons}
                                    onSelectedRowsChange={handleChangeSelectRow}
                                />
                                :
                                <h2 align="center" className='mt-5'>No Query Selected</h2>
                            }
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={sendQueryActionFun}>Send Query</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Alert
                isAlert={isAlert}
                isAlertToggle={isAlertToggle}
                alertMessage={alertMessage}
                isAlertToggleClose={isAlertToggleClose}
            />
            <ToastContainer/>
        </div>
    )
}

export default QueryActionModal;
