import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import Header from "components/Headers/Header.js";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import axios from "axios"
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


class SubCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            visibleListing: false,
            category: [],
            vendors: [],
            listings: [],
            recordsVendors: [],
            records: [],
            loading: true,
            ShowSeo: false,
            metaTitle: "",
            metaDes: "",
            metaKey: "",
            Options: [],
            Catid:""
        };
        this.columns = [
            {
                key: "sub_category",
                text: "Sub Category",
                className: "category",
                align: "left",
                sortable: true,
            },
            {
                key: "Category",
                text: "Category",
                className: "category",
                align: "left",
                sortable: true,
            },

            {
                key: "action",
                text: "Seo",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <>
                            <Fragment>
                                <button onClick={() => this.addSeo(record.id)}
                                    className="btn btn-danger btn-sm" >
                                    <i className="fa fa-eye"></i>
                                </button>
                            </Fragment>
                        </>
                    );
                }
            },



            // {
            //     key: "action",
            //     text: "Action",
            //     className: "action",
            //     width: 100,
            //     align: "left",
            //     sortable: false,
            //     cell: record => { 
            //         return (
            //             <>
            //             <Fragment>
            //                 <button onClick={this.show.bind(this)}
            //                     className="btn btn-danger btn-sm" >
            //                     <i className="fa fa-eye"></i>
            //                 </button>
            //             </Fragment>
            //             </>
            //         );
            //     }
            // }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: true,
                csv: true
            }
        }



        // this.extraButtons =[
        //     {
        //         className:"btn btn-primary buttons-pdf",
        //         title:"Export TEst",
        //         children:[
        //             <span>
        //                 <i className="fa fa-user fa fa-print" aria-hidden="true"></i>
        //             </span>
        //         ],
        //         onClick:(event)=>{
        //             console.log(event);
        //         },
        //     },
        //     {
        //         className:"btn btn-primary buttons-pdf",
        //         title:"Export TEst",
        //         children:[
        //             <span>
        //             <i className="fa fa-user fa fa-print" aria-hidden="true"></i>
        //             </span>
        //         ],
        //         onClick:(event)=>{
        //             console.log(event);
        //         },
        //         onDoubleClick:(event)=>{
        //             console.log("doubleClick")
        //         }
        //     },
        // ]
    }




  


    componentDidMount() {
        if (!localStorage.getItem("admin_token")) {
            this.props.history.push("/auth/login")
        } else {
            this.getListingData()
        }
    }
    getListingData = async () => {
        this.setState({
            loading: false
        })
        await axios.get(`${process.env.REACT_APP_API}/api/auth/getcategories/1`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                this.setState({
                    category: res.data.data1,
                })
                this.setTableData()
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
            this.setState({
                loading: true
            })
        })
    }

 
    setTableData = () => {
        console.log("faisal", this.state.category)
        let dat = []
        this.state.category && this.state.category.map((data, i) => {
           data.SubCategoryMeta.map((data1,i)=>{
            return dat.push({
                "id": data1._id,
                "Category": data.Category,
                "sub_category":data1.Name
            })
           }) 
        
        })
        this.setState({
            records: dat
        })

    }
addSeo=(dat)=>{
console.log("data",dat)
this.setState({
    Catid: dat,
    ShowSeo: true
})
this.state.category && this.state.category.map((data, i) => {
    data.SubCategoryMeta.map((data1,i)=>{
        if(data1._id==dat){
            this.setState({
                metaTitle: data1.Meta_Title,
                    metaDes: data1.Meta_Description,
                    Options: data1.Meta_Keywords
            })
        }
    }) 
 
 })
}
addSeoData = async (e) => {
    e.preventDefault()
    let data = {
        id: this.state.Catid,
        Meta_Title: this.state.metaTitle,
        Meta_Description: this.state.metaDes,
        Meta_Keywords: this.state.Options
    }
    await axios.post(`${process.env.REACT_APP_API}/api/auth/addsubcatmeta`, data, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("admin_token")}`
        }
    }).then((res) => {
        if (res.data.Error == false) {
            this.setState({
                ShowSeo:false
            })
            this.getListingData()
        }
        else if (res.data.token == false) {
            localStorage.removeItem("admin_token")
            this.props.history.push("/auth/login")
        }
    })
}
addOptions = (e) => {
    e.preventDefault()
    let p = this.state.Options
    let value = this.state.metaKey
    if (value.length > 0) {
        p.push(value)
        this.setState({ Options: p, optionValue: "" })
    }
    this.setState({
        metaKey:""
    })
}
removeOptions = (id) => {
    let arr = this.state.Options.filter((data, i) => {
        if (id !== i) {
          return data
        }
    })
    this.setState({
        Options:arr
    })
    
}
    render() {
        return (

            <>
                <Header />
                <div className="mt--7 container-fluid">
                    <div className="shadow card">
                        <div className="border-0 card-header">
                            <h3 class="mb-0">Category</h3>
                        </div>
                        {this.state.loading ?
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                extraButtons={this.extraButtons}
                            />
                            :
                            <div className="loader">
                                <Loader
                                    type="Puff"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                />
                            </div>
                        }
                    </div>
                </div>
                <Rodal visible={this.state.ShowSeo} onClose={()=>{this.setState({ShowSeo:false})}} width="900" height="600" >
                    <div className="header_view_popup">Enter Detail</div>
                    <div className="main_listing_view">
                        <div className="listing_goes">
                            <form>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="metaTitle">Meta Title</label>
                                        <input value={this.state.metaTitle} onChange={(e) => { this.setState({ metaTitle: e.target.value }) }} type="text" className="form-control" id="metaTitle" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="metaDes">Meta Description</label>
                                        <input value={this.state.metaDes} onChange={(e) => { this.setState({ metaDes: e.target.value }) }} type="text" className="form-control" id="metaDes" />
                                    </div>
                                </div>

                                <label htmlFor="Options">Meta Keywords</label>
                                <div className="form-group col-md-6">
                                    <div className="parent_plus d-flex align-items-center">
                                        <input value={this.state.metaKey} onChange={(e) => { this.setState({ metaKey: e.target.value }) }} type="text" className="form-control" id="metaKey" />
                                        <button id="22" className="btn btn-primary ml-2" style={{ fontSize: "20px", padding: "4px 12px" }} onClick={this.addOptions} >+</button>
                                    </div>
                                </div>
                                {this.state.Options && this.state.Options.map((data, i) => {
                                    return (
                                        <div key={i} className="form-group col-md-6">
                                            <div className="parent_plus d-flex align-items-center">

                                                <input value={data}  type="text" className="form-control" id={i} />
                                                <button className="btn btn-danger ml-2" style={{ fontSize: "20px", padding: "4px 12px" }} onClick={(e) =>{e.preventDefault(); this.removeOptions(i)}}>-</button>
                                            </div>
                                        </div>
                                    )
                                })}
                                <button onClick={this.addSeoData} type="submit" className="btn btn-primary">Submit</button>
                            </form>
                        </div>
                    </div>
                </Rodal>
            </>


        )
    }
}

export default SubCategory;;