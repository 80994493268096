import Config from '../config';

export const config = {
    submitQuery: `${Config.site_api}/api/auth/submitQuery`,
    getcategories : `${Config.site_api}/api/auth/getcategories/1`,
    getQuery: `${Config.site_api}/api/auth/getQuery`,
    queryAction: `${Config.site_api}/api/auth/queryAction`,
    getAllListing : `${Config.site_api}/api/auth/getAllListing`,
    actionStatusChange : `${Config.site_api}/api/auth/actionStatusChange`,
    getQuerySentListing: `${Config.site_api}/api/auth/getQuerySentListing`,
}