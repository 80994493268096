import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import Header from "components/Headers/Header.js";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import axios from "axios"
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class Venues extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            listingsVenue: [],
            approved: null,
            reviews: [],
            Reviewrecords: [],
            listingid: "",
            loading: true,
            ShowSeo: false,
            metaTitle: "",
            metaDes: "",
            metaKey: "",
            list: null,
            Options: [],
            Showsms: false,
            sms: null
        };
        this.columns = [
            {
                key: "id",
                text: "Id",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "business_name",
                text: "Business Name",
                className: "business_name",
                align: "left",
                sortable: true,
            },
            {
                key: "state",
                text: "State",
                className: "address",
                align: "left",
                sortable: true
            },
            {
                key: "suburb",
                text: "Suburb",
                className: "address",
                align: "left",
                sortable: true
            },
            {
                key: "tagline",
                text: "Tagline",
                className: "tagline",
                sortable: true
            },
            {
                key: "reviews",
                text: "Reviews",
                className: "postcode",
                sortable: true
            },
            {
                key: "mobilenumber",
                text: "Mobilenumber",
                className: "postcode",
                sortable: true
            },
            {
                key: "status",
                text: "Status",
                className: "postcode",
                sortable: true
            },
            // {
            //     key: "contacted",
            //     text: "# of Contacted",
            //     className: "postcode",
            //     sortable: true
            // },
            // {
            //     key: "pageviews",
            //     text: "Page Views",
            //     className: "postcode",
            //     sortable: true
            // },
            {
                key: "approved",
                text: "Approved",
                className: "approved",
                sortable: true,
                cell: record => {
                    return (
                        <>
                            {
                                record.status == ("Activated") ?
                                    <Fragment>
                                        <div>
                                            <div style={{ cursor: "pointer" }} class="custom-control custom-switch">
                                                <input style={{ cursor: "pointer" }} value={record.approved} checked={record.approved} onChange={() => this.togDat(record)} type="checkbox" class="custom-control-input" id={`customSwitch${record.id}`} />
                                                <label style={{ cursor: "pointer" }} class="custom-control-label" htmlFor={`customSwitch${record.id}`}></label>
                                            </div>
                                        </div>
                                    </Fragment>
                                    :
                                    record.status == ("Draft") ?
                                    <Fragment>
                                        <div>
                                            <div style={{ cursor: "pointer" }} class="custom-control custom-switch">
                                                <input style={{ cursor: "pointer" }} value={record.approved} checked={record.approved} onChange={() => this.togDat(record)} type="checkbox" class="custom-control-input" id={`customSwitch${record.id}`} />
                                                <label style={{ cursor: "pointer" }} class="custom-control-label" htmlFor={`customSwitch${record.id}`}></label>
                                            </div>
                                        </div>
                                    </Fragment>
                                    :
                                    ""
                            }

                        </>
                    );
                }
            },
            {
                key: "action",
                text: "Send Invoice",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <>
                            <Fragment>
                                <button disabled={record.status == "Activated" ? false : true} onClick={() => { console.log("send") }}
                                    className="btn btn-danger btn-sm" >
                                    <i class="fa fa-paper-plane"></i>
                                </button>
                            </Fragment>
                        </>
                    );
                }
            },
            {
                key: "action",
                text: "Send Sms",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <>
                            <Fragment>
                                <button onClick={() => { this.setState({ listing_id:record.id,mobilenumber:record.mobilenumber,Showsms: true }) }}
                                    className="btn btn-danger btn-sm" >
                                    <i class="fa fa-paper-plane"></i>
                                </button>
                            </Fragment>
                        </>
                    );
                }
            },
            {
                key: "action",
                text: "Edit Reviews",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <>
                            <Fragment>
                                <button onClick={() => { this.show(record) }}
                                    className="btn btn-danger btn-sm" >
                                    <i className="fa fa-pencil-square-o"></i>
                                </button>
                            </Fragment>
                        </>
                    );
                }
            },
            {
                key: "action",
                text: "Seo",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <>
                            <Fragment>
                                <button onClick={() => { this.showSeo(record) }}
                                    className="btn btn-danger btn-sm" >
                                    <i className="fa fa-pencil-square-o"></i>
                                </button>
                            </Fragment>
                        </>
                    );
                }
            }
        ];
        this.Reviewcolumns = [
            {
                key: "email",
                text: "Email",
                className: "email",
                align: "left",
                sortable: true,
            },
            {
                key: "name",
                text: "Name",
                className: "name",
                align: "left",
                sortable: true
            },
            {
                key: "review",
                text: "Review",
                className: "postcode",
                sortable: true
            },
            {
                key: "rating",
                text: "Rating",
                className: "postcode",
                sortable: true
            },
            {
                key: "approved",
                text: "Approved",
                className: "approved",
                sortable: true,
                cell: record => {
                    return (
                        <>
                            <Fragment>

                                <div style={{ cursor: "pointer" }} class="custom-control custom-switch">
                                    <input style={{ cursor: "pointer" }} value={record.approved} checked={record.approved} onChange={() => this.togReviewDat(record)} type="checkbox" class="custom-control-input" id={`customSwitch${record.id}`} />
                                    <label style={{ cursor: "pointer" }} class="custom-control-label" htmlFor={`customSwitch${record.id}`}></label>
                                </div>

                            </Fragment>
                        </>
                    );
                }
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: true,
                csv: true
            }
        }

        this.state = {
            records: []
        }
        // this.extraButtons =[
        //     {
        //         className:"btn btn-primary buttons-pdf",
        //         title:"Export TEst",
        //         children:[
        //             <span>
        //                 <i className="fa fa-user fa fa-print" aria-hidden="true"></i>
        //             </span>
        //         ],
        //         onClick:(event)=>{
        //             console.log(event);
        //         },
        //     },
        //     {
        //         className:"btn btn-primary buttons-pdf",
        //         title:"Export TEst",
        //         children:[
        //             <span>
        //             <i className="fa fa-user fa fa-print" aria-hidden="true"></i>
        //             </span>
        //         ],
        //         onClick:(event)=>{
        //             console.log(event);
        //         },
        //         onDoubleClick:(event)=>{
        //             console.log("doubleClick")
        //         }
        //     },
        // ]
    }

    editRecord(record) {
        console.log("Edit Record", record);
    }

    deleteRecord(record) {
        console.log("Delete Record", record);
    }

    show = async (list) => {
        this.setState({
            listingid: list.id
        })
        setTimeout(() => {
            this.getReviewData()

        }, 300)

        this.setState({ visible: true });
    }

    hide() {
        this.setState({ visible: false });
    }
    handleCkEditorDescription = (event, editor) => {
        const data = editor.getData();
        this.setState({
            description: data
        })
        // console.log(data);
    }
    togDat = async (list) => {
        console.log(list)
        let data = {
            id: list.id
        }
        await axios.post(`${process.env.REACT_APP_API}/api/auth/adminappordisappVenue`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                this.getVenueData()
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
        })

    }
    componentDidMount() {
        if (!localStorage.getItem("admin_token")) {
            this.props.history.push("/auth/login")
        } else {
            this.getVenueData()
        }
    }
    getVenueData = async () => {
        this.setState({
            loading: false
        })
        await axios.get(`${process.env.REACT_APP_API}/api/auth/admingetactivatedvenue`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                this.setState({
                    listingsVenue: res.data.data,
                })
                this.setTableData()
            } else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
            this.setState({
                loading: true
            })
        })
    }
    setTableData = () => {
        console.log("faisal", this.state.listingsVenue)
        let data1 = []
        this.state.listingsVenue && this.state.listingsVenue.map((data, i) => {
            return data1.push({
                "id": data._id,
                "business_name": data.business_name,
                "state": data.Address.State,
                "suburb":data.Address.City,
                "tagline": data.tagline,
                "status": data.Status,
                "approved": data.approved ? data.approved : "",
                "mobilenumber":data.Mobile_Number
            })
        })
        this.setState({
            records: data1
        })

    }
    getReviewData = async () => {
        let data = {
            listing_id: this.state.listingid
        }
        await axios.post(`${process.env.REACT_APP_API}/api/auth/admingetcustomerreviews`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                this.setState({
                    reviews: res.data.Data
                })
                this.setReviewTableData()
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
        })
    }
    setReviewTableData = () => {
        let data1 = []
        this.state.reviews && this.state.reviews.map((data, i) => {
            return data1.push({

                "id": data._id,
                "email": data.email,
                "name": data.name,
                "review": data.description,
                "rating": `${data.star_reviews} Stars`,
                "approved": data.approved
            })
        })
        this.setState({
            Reviewrecords: data1
        })
    }
    togReviewDat = async (record) => {
        let data = {
            id: record.id,
            approved: !record.approved
        }
        await axios.post(`${process.env.REACT_APP_API}/api/auth/adminapprovereview`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                this.getReviewData()
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
        })
    }
    showSeo = (record) => {
        this.setState({
            listingid: record.id,
            ShowSeo: true
        })
        this.state.listingsVenue.map((data) => {
            if (data._id == record.id) {
                this.setState({
                    metaTitle: data.Meta_Title,
                    metaDes: data.Meta_Description,
                    Options: data.Meta_Keywords
                })
            }
        })
    }
    hideSeo = () => {
        this.setState({ ShowSeo: false });
    }
    hideSms = () => {
        this.setState({ Showsms: false });
    }
    sendsms =async () => {
        console.log("sms", this.state.mobilenumber)
        let data={
            message:this.state.description,
            Mobile_Number:this.state.mobilenumber,
            listing_id:this.state.listing_id
        }
        await axios.post(`${process.env.REACT_APP_API}/api/auth/sendvendormessage`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                this.hideSms()
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
            else{
                alert("message not sent to vendor")
            }
        })
    }
    addSeo = async (e) => {
        e.preventDefault()
        let data = {
            id: this.state.listingid,
            Meta_Title: this.state.metaTitle,
            Meta_Description: this.state.metaDes,
            Meta_Keywords: this.state.Options
        }
        await axios.post(`${process.env.REACT_APP_API}/api/auth/updateseo`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                this.hideSeo()
                this.getVenueData()
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
        })
    }
    addOptions = (e) => {
        e.preventDefault()
        let p = this.state.Options
        let value = this.state.metaKey
        if (value.length > 0) {
            p.push(value)
            this.setState({ Options: p, optionValue: "" })
        }
    }
    removeOptions = (id) => {
        let arr = this.state.Options.filter((data, i) => {
            if (id !== i) {
                return data
            }
        })
        this.setState({
            Options: arr
        })

    }

    render() {
        return (

            <>
                <Header />
                <div className="mt--7 container-fluid">
                    <div className="shadow card">
                        <div className="border-0 card-header">
                            <h3 class="mb-0">Listing</h3>
                        </div>
                        {this.state.loading ?
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                 extraButtons={this.extraButtons}
                                className="table table-bordered table-striped response_tabel"
                               
                            />
                            :
                            <div className="loader">
                                <Loader
                                    type="Puff"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                />
                            </div>
                        }

                    </div>
                </div>
                <Rodal visible={this.state.visible} onClose={this.hide.bind(this)} width="900" height="600" >
                    <div className="header_view_popup">View Detail</div>
                    <div className="main_listing_view">
                        <div className="listing_goes">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.Reviewrecords}
                                columns={this.Reviewcolumns}
                                extraButtons={this.extraButtons}
                            />
                        </div>
                    </div>
                </Rodal>
                <Rodal visible={this.state.ShowSeo} onClose={this.hideSeo} width="900" height="600" >
                    <div className="header_view_popup">Enter Detail</div>
                    <div className="main_listing_view">
                        <div className="listing_goes">
                            <form>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="metaTitle">Meta Title</label>
                                        <input value={this.state.metaTitle} onChange={(e) => { this.setState({ metaTitle: e.target.value }) }} type="text" className="form-control" id="metaTitle" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="metaDes">Meta Description</label>
                                        <input value={this.state.metaDes} onChange={(e) => { this.setState({ metaDes: e.target.value }) }} type="text" className="form-control" id="metaDes" />
                                    </div>
                                </div>

                                <label htmlFor="Options">Meta Keywords</label>
                                <div className="form-group col-md-6">
                                    <div className="parent_plus d-flex align-items-center">
                                        <input value={this.state.metaKey} onChange={(e) => { this.setState({ metaKey: e.target.value }) }} type="text" className="form-control" id="metaKey" />
                                        <button className="btn btn-primary ml-2" style={{ fontSize: "20px", padding: "4px 12px" }} onClick={this.addOptions} >+</button>
                                    </div>
                                </div>
                                {this.state.Options && this.state.Options.map((data, i) => {
                                    return (
                                        <div key={i} className="form-group col-md-6">
                                            <div className="parent_plus d-flex align-items-center">

                                                <input value={data} type="text" className="form-control" id={i} />
                                                <button className="btn btn-danger ml-2" style={{ fontSize: "20px", padding: "4px 12px" }} onClick={(e) => { e.preventDefault(); this.removeOptions(i) }}>-</button>
                                            </div>
                                        </div>
                                    )
                                })}
                                <button onClick={this.addSeo} type="submit" className="btn btn-primary">Submit</button>
                            </form>
                        </div>
                    </div>
                </Rodal>

                <Rodal visible={this.state.Showsms} onClose={this.hideSms} width="900" height="600" >
                    <div className="header_view_popup">SMS</div>
                    <div className="main_listing_view">
                        <div className="listing_goes">
                        <textarea
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="7"
            onChange={(e)=>{this.setState({
                description:e.target.value
            })}}
            />
                        
                            <button  onClick={this.sendsms} className="btn btn-primary mt-5">Send</button>
                        </div>
                    </div>
                </Rodal>
            </>


        )
    }
}

export default Venues;;