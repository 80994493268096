import React, { Component, Fragment, useEffect, useState } from 'react';
import { render } from 'react-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import DataTable, { TableColumn, TableStyles } from "react-data-table-component";
import Header from "components/Headers/Header.js";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import axios from "axios"
import { Link } from 'react-router-dom';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TextEditor from './textEditor';
import Alert from './alert';
import movies from "./data";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';

const configs = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
        excel: true,
        csv: true
    }
}
const columns = [
    {
        id: 1,
        name: "Client Name",
        selector: (row) => row.vendor_id?.name,
        sortable: true,
        // right: true,
        reorder: true
    },
    {
        id: 2,
        name: "Client Email",
        selector: (row) => row.vendor_id?.email,
        sortable: true,
        // right: true,
        reorder: true
    },
    {
        id: 3,
        name: "Name",
        selector: (row) => row.Name,
        sortable: true,
        reorder: true
    },
    {
        id: 4,
        name: "Mobile_Number",
        selector: (row) => row.Mobile_Number,
        sortable: true,
        // right: true,
        reorder: true
    },
    {
        id: 5,
        name: "Category",
        selector: (row) => row.Category,
        sortable: true,
        reorder: true
    },
    {
        id: 6,
        name: "Payment",
        selector: (row) => row.Payment,
        sortable: true,
        // right: true,
        reorder: true
    },
    {
        id: 7,
        name: "Profile_Pic",
        selector: (row) => row.Profile_Pic,
        sortable: true,
        // right: true,
        reorder: true
    },
    // {
    //     id: 6,
    //     name: "mobile",
    //     selector: (row) => row.mobile,
    //     sortable: true,
    //     // right: true,
    //     reorder: true
    // },
    {
        id: 8,
        name: "Slug",
        selector: (row) => row.Slug,
        sortable: true,
        // right: true,
        reorder: true
    },
    {
        id: 9,
        name: "Status",
        selector: (row) => row.Status,
        sortable: true,
        // right: true,
        reorder: true
    },
    {
        id: 10,
        name: "Sub_Category",
        selector: (row) => row.Sub_Category,
        sortable: true,
        // right: true,
        reorder: true
    },
];
const SendQueryDetailsModal = (props) => {
    const api = new API()
    const { toggle, visible, selectedData } = props
    const [isAlert, setIsAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")

    const [searchTerm, setSearchTerm] = useState("")
    const [listings, setListings] = useState([])

    useEffect(() => {
        if(selectedData && selectedData.length>0){
            getSentQueryVendorDetails()
        }
    }, [selectedData])
    
    const isAlertToggleClose = () => {
        setIsAlert(false);
    }
    const isAlertToggle = (msg) => {
        setIsAlert(!isAlert);

    };

    const getSentQueryVendorDetails = async() => {
        let data = {
            query_id : selectedData[0]?._id
        }
        let result = await api.post(config.getQuerySentListing, data)
        if (result && result.code == 200) {
            setListings(result.data)
        } else {

        }
    }



    return (
        <div>
            <Modal
                isOpen={visible}
                toggle={toggle}
                size="xl"
                // style={{maxWidth: '700px', width: '100%'}}
                className="lg">
                <ModalHeader className='modal_header_query' toggle={toggle} charCode={<i className='fa fa-close '></i>}>

                    <h3>Send query vendor details</h3>
                </ModalHeader>
                <ModalBody>

                    <div className='row'>
                        <div className='col-md-6'>
                        </div>
                        <div className='col-md-6'>
                            <div className="search-box-size">
                                <form>
                                    <div className="input-group">
                                        <input type="text" className="form-control" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search By Name.." />
                                        <div className="input-group-btn ">
                                            <button className="btn btn-default btn btn-secondary" type="submit">
                                                <i className="glyphicon glyphicon-search  fa fa-search text-black"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className='container'>

                            {selectedData?.length > 0 &&
                                <DataTable
                                    // title="Query"
                                    config={configs}
                                    columns={columns}
                                    data={listings.filter((item) => {
                                     
                                        if (searchTerm === "") {
                                            return item;
                                        } else if (
                                            item.Name.toLowerCase().includes(searchTerm.toLowerCase())
                                        ) {
                                            return item;
                                        }
                                    })}
                                    defaultSortFieldId={1}
                                    // sortIcon={<SortIcon />}
                                    pagination
                                    selectableRows
                                    // extraButtons={extraButtons}
                                    // onSelectedRowsChange={handleChangeSelectRow}
                                />
                            }
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Alert
                isAlert={isAlert}
                isAlertToggle={isAlertToggle}
                alertMessage={alertMessage}
                isAlertToggleClose={isAlertToggleClose}
            />
            <ToastContainer />
        </div>
    )
}

export default SendQueryDetailsModal;
