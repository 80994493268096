import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import Header from "components/Headers/Header.js";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import axios from "axios"
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false, listings: [], approved: null, records: [], question: "", answer: "", delete: false, faq_id: '',
            DeleteModal: false,
            loading: true
        };
        this.columns = [
            {
                key: "question",
                text: "Question",
                className: "name",
                align: "left",
                sortable: true,

            }, ,
            {
                key: "status",
                text: "Status",
                className: "approved",
                sortable: true
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <>
                            <Fragment>
                                <Link onClick={() => this.show(record.id)}
                                    className="btn btn-danger btn-sm" >
                                    <i className="fa fa-pencil-square-o"></i>
                                </Link>
                                <button onClick={() => this.showDeleteModal(record)}
                                    className="btn btn-danger btn-sm" >
                                    <i class="fa fa-trash" aria-hidden="true"></i>

                                </button>
                            </Fragment>
                        </>
                    );
                }
            }
        ];


        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: true,
                csv: true
            }
        }

        // this.extraButtons =[
        //     {
        //         className:"btn btn-primary buttons-pdf",
        //         title:"Export TEst",
        //         children:[
        //             <span>
        //                 <i className="fa fa-user fa fa-print" aria-hidden="true"></i>
        //             </span>
        //         ],
        //         onClick:(event)=>{
        //             console.log(event);
        //         },
        //     },
        //     {
        //         className:"btn btn-primary buttons-pdf",
        //         title:"Export TEst",
        //         children:[
        //             <span>
        //             <i className="fa fa-user fa fa-print" aria-hidden="true"></i>
        //             </span>
        //         ],
        //         onClick:(event)=>{
        //             console.log(event);
        //         },
        //         onDoubleClick:(event)=>{
        //             console.log("doubleClick")
        //         }
        //     },
        // ]
    }
    componentDidMount() {
        if (!localStorage.getItem("admin_token")) {
            this.props.history.push("/auth/login")
        } else {
            this.getListingData()
        }
    }
    getListingData = async () => {
        this.setState({
            loading: false
        })
        await axios.get(`${process.env.REACT_APP_API}/api/auth/getallfaqs`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                this.setState({
                    listings: res.data.Data,
                })
                this.setTableData(this.state.listings)
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
            this.setState({
                loading: true
            })
        })
    }
    setTableData = (data) => {
        console.log("faisal", this.state.category)
        let data1 = []
        data && data.map((dat, i) => {
            return data1.push({

                "id": dat._id,
                "question": dat.Question,
                "status": dat.Active ? "Active" : "Deactive"
            })
        })
        this.setState({
            records: data1
        })

    }
    editRecord(record) {
        console.log("Edit Record", record);
    }

    deleteRecord(record) {
        console.log("Delete Record", record);
    }
    showDeleteModal = (record) => {
        this.setState({
            faq_id: record.id,
            DeleteModal: true
        })
    }
    deleteData = async () => {
        let data = {
            id: this.state.faq_id

        }
        await axios.post(`${process.env.REACT_APP_API}/api/auth/deletefaq`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.success == true) {
                this.setState({
                    DeleteModal: false
                })
                this.getListingData()
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
        })

    }
    show = async (record) => {
        let id = record
        await axios.get(`${process.env.REACT_APP_API}/api/auth/getfaq/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                this.setState({
                    faq_id: res.data.Data[0]._id,
                    question: res.data.Data[0].Question,
                    answer: res.data.Data[0].Answer,
                    delete: res.data.Data[0].Active
                })
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
        })
        this.setState({ visible: true });
    }

    hide() {
        this.setState({ visible: false });
    }
    editfaqsubmit = async (e) => {
        e.preventDefault()
        let faq = {
            id: this.state.faq_id,
            Question: this.state.question,
            Answer: this.state.asnwer
        }

        await axios.post(`${process.env.REACT_APP_API}/api/auth/updatefaqs`, faq, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                this.getListingData()
                this.setState({ visible: false })
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
        })
    }
    handleCkEditorDescription = (event, editor) => {
        console.log(editor.getData())
        const data = editor.getData();
        this.setState({
            asnwer: data
        })
    }
    delete = async () => {
        let data = {
            id: this.state.faq_id
        }
        await axios.post(`${process.env.REACT_APP_API}/api/auth/statusfaq`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {

            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
        })
    }

    render() {
        return (

            <>
                <Header />
                <div className="mt--7 container-fluid">
                    <div className="shadow card">
                        <div className="border-0 card-header d-flex align-items-center justify-content-between">
                            <h3 class="mb-0">Faqs</h3>
                            <div className="add_blog">
                                <Link to='/admin/addfaq' className="btn btn-primary">Add Faqs</Link>
                            </div>
                        </div>
                        {this.state.loading ?
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                extraButtons={this.extraButtons}
                            />
                            :
                            <div className="loader">
                                <Loader
                                    type="Puff"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                />
                            </div>
                        }
                    </div>
                </div>
                <Rodal visible={this.state.visible} onClose={this.hide.bind(this)} width="900" height="600" >
                    <div className="header_view_popup">Edit Faqs</div>
                    <div class="form-group">
                        <label class="form-control-label">Question</label>
                        <input placeholder="" type="text" value={this.state.question} onChange={(e) => this.setState({ question: e.target.value })} class="form-control-alternative form-control" />
                    </div>
                    <div class="form-group">
                        <label class="form-control-label">Answer</label>
                        <CKEditor
                            editor={ClassicEditor}
                            config={{ removePlugins: ['CKFinderUploadAdapter', 'CKFinder', 'EasyImage', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed'], }}
                            data={this.state.answer}
                            onInit={(editor) => { }}
                            onChange={this.handleCkEditorDescription}
                        />
                        {/* <textarea type="text" onChange={(e) => this.setState({ asnwer: e.target.value })} className="form-control-alternative form-control"></textarea> */}
                    </div>
                    <div style={{ cursor: "pointer", marginBottom: "20px" }} class="custom-control custom-switch">
                        <input style={{ cursor: "pointer" }} value={this.state.delete} checked={this.state.delete} onChange={() => { this.delete(); this.setState({ delete: !this.state.delete }) }} type="checkbox" class="custom-control-input" id={`customSwitch1`} />
                        <label style={{ cursor: "pointer" }} class="custom-control-label" htmlFor={`customSwitch1`}>Status</label>
                    </div>
                    <div className="submit_faq">
                        <button type="button" className="btn btn-primary" onClick={this.editfaqsubmit}>submit</button>
                    </div>
                </Rodal>
                {
                    this.state.DeleteModal ?
                        <Rodal visible={this.state.DeleteModal} onClose={() => this.setState({ DeleteModal: false })} width="600" height="300" >
                            <div className="header_view_popup">Delete Confirmation</div>
                            <div className="main_listing_view">
                                <div className="listing_goes mb-4">
                                    Are You Sure Want To Delete?
                                </div>
                                <button onClick={this.deleteData} type="submit" className="btn btn-primary mt-4">Submit</button>
                            </div>
                        </Rodal>
                        :
                        ""

                }
            </>


        )
    }
}

export default Faq;