import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import Header from "components/Headers/Header.js";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import axios from "axios"
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class Plans extends Component {

    state = {
        selectedType : "",
        visible: false,
        listings: [],
        listingsVenue: [],
        Name: "",
        Price: "",
        PackageFor: "",
        Options: [],
        plan_id: "",
        approved: null,
        plan: {},
        visiblePlan: false,
        optionValue: "",
        OptionsPlan: [],
        PlanName: "",
        PlanPrice: '',
        records: [],
        recordsVenue: [],
        delete: false,
        DeleteModal: false,
        err: false,
        loading: true
    };
    columns = [
        {
            key: "name",
            text: "Name",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "price",
            text: "Price",
            className: "price",
            align: "left",
            sortable: true
        },
        {
            key: "status",
            text: "Status",
            className: "price",
            align: "left",
            sortable: true
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <>
                        <Fragment>
                            <button onClick={() => this.show(record, "vendor")}
                                className="btn btn-danger btn-sm" >
                                <i className="fa fa-pencil-square-o"></i>
                            </button>
                            <button onClick={() => this.showDeleteModal(record, "vendor")}
                                className="btn btn-danger btn-sm" >
                                <i class="fa fa-trash" aria-hidden="true"></i>

                            </button>
                        </Fragment>
                    </>
                );
            }
        }
    ];
    columnsVenue = [
        {
            key: "name",
            text: "Name",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "price",
            text: "Price",
            className: "price",
            align: "left",
            sortable: true
        },
        {
            key: "status",
            text: "Status",
            className: "price",
            align: "left",
            sortable: true
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <>
                        <Fragment>
                            <button onClick={() => this.show(record, "venue")}
                                className="btn btn-danger btn-sm" >
                                <i className="fa fa-pencil-square-o"></i>
                            </button>
                            <button onClick={() => this.showDeleteModal(record, "venue")}
                                className="btn btn-danger btn-sm" >
                                <i class="fa fa-trash" aria-hidden="true"></i>

                            </button>
                        </Fragment>
                    </>
                );
            }
        }
    ];
    
    config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        button: {
            excel: true,
            csv: true
        }
    }


    // this.extraButtons =[
    //     {
    //         className:"btn btn-primary buttons-pdf",
    //         title:"Export TEst",
    //         children:[
    //             <span>
    //                 <i className="fa fa-user fa fa-print" aria-hidden="true"></i>
    //             </span>
    //         ],
    //         onClick:(event)=>{
    //             console.log(event);
    //         },
    //     },
    //     {
    //         className:"btn btn-primary buttons-pdf",
    //         title:"Export TEst",
    //         children:[
    //             <span>
    //             <i className="fa fa-user fa fa-print" aria-hidden="true"></i>
    //             </span>
    //         ],
    //         onClick:(event)=>{
    //             console.log(event);
    //         },
    //         onDoubleClick:(event)=>{
    //             console.log("doubleClick")
    //         }
    //     },
    // ]


    editRecord = (record) => {
        console.log("Edit Record", record);
    }

    deleteRecord = (record) => {
        console.log("Delete Record", record);
    }
    delete = async () => {
        let data = {
            id: this.state.plan_id
        }
        await axios.post(`${process.env.REACT_APP_API}/api/auth/subscriptionschangestatus`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {

            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
        })
    }
    showDeleteModal = (record, type) => {
        this.setState({
            plan_id: record.id,
            selectedType: type,
            DeleteModal: true
        })
    }
    deleteData = async () => {
        let data = {
            id: this.state.plan_id,
            type : this.state.selectedType
        }
        await axios.post(`${process.env.REACT_APP_API}/api/auth/deletesubscriptions`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.success == true) {
                this.setState({
                    DeleteModal: false
                })
                this.getListingData()
                this.getListingDataVenue()
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
        })
    }
    show = async (a, type) => {
        this.setState({
            selectedType : type
        })
        let id = a.id
        let apiURL = type == "venue" ? "subscriptionVenue" : "subscription"
        // alert(apiURL)
        await axios.get(`${process.env.REACT_APP_API}/api/auth//${apiURL}/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                this.setState({
                    plan_id: id,
                    Name: res.data.data[0].Name,
                    Price: res.data.data[0].Price,
                    Options: res.data.data[0].Options,
                    delete: res.data.data[0].Active
                })
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
        })
        this.setState({ visible: true });
    }

    hide = () => {
        this.setState({ visible: false });
    }

    componentDidMount() {

        if (!localStorage.getItem("admin_token")) {
            this.props.history.push("/auth/login")
        } else {
            this.getListingData()
            this.getListingDataVenue()
        }
    }
    getListingData = async () => {
        this.setState({
            loading: false
        })
        await axios.get(`${process.env.REACT_APP_API}/api/auth/adminsubscriptions`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                this.setState({
                    listings: res.data.data,
                })
                this.setTableData()
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
            this.setState({
                loading: true
            })
        })
    }
    getListingDataVenue = async () => {
        this.setState({
            loading: false
        })
        await axios.get(`${process.env.REACT_APP_API}/api/auth/adminsubscriptionsVenue`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                this.setState({
                    listingsVenue: res.data.data,
                })
                this.setTableData()
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
            this.setState({
                loading: true
            })
        })
    }
    setTableData = () => {
        console.log("faisal", this.state.listings)
        let data1 = []
        let data1Venue = []

        this.state.listings && this.state.listings.map((data, i) => {
            return data1.push({
                "id": data._id,
                "name": data.Name,
                "price": data.Price,
                "status": data.Active ? "Active" : "Deactive"
            })
        })
        this.state.listingsVenue && this.state.listingsVenue.map((data, i) => {
            return data1Venue.push({

                "id": data._id,
                "name": data.Name,
                "price": data.Price,
                "status": data.Active ? "Active" : "Deactive"
            })
        })
        this.setState({
            records: data1,
            recordsVenue: data1Venue
        })
    }
    editPlan = async (e) => {
        e.preventDefault()
        let Name = this.state.Name.charAt(0).toUpperCase() + this.state.Name.slice(1)
        let data = {
            id: this.state.plan_id,
            Name: Name,
            Price: this.state.Price,
            Options: this.state.Options,
            type : this.state.selectedType,
        }
        console.log(data)
        await axios.post(`${process.env.REACT_APP_API}/api/auth//adminupdateplans`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                this.getListingData()
                this.getListingDataVenue()
                this.setState({ visible: false })
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
        })

    }
    setOptions = (e, i) => {
        const newIds = this.state.Options.slice() //copy the array
        newIds[i] = (e.target.value).toUpperCase() //execute the manipulations
        this.setState({ Options: newIds });
    }
    PlanModal = async () => {
        this.setState({
            visiblePlan: true
        })
    }
    hidePlan = () => {
        this.setState({
            visiblePlan: false
        })
    }
    addOptions = (e) => {
        e.preventDefault()
        let p = this.state.OptionsPlan
        let value = this.state.optionValue
        if (value.length > 0) {
            p.push(value)
            this.setState({ OptionsPlan: p, optionValue: "" })
        }
    }
    removeOptions = (id) => {
        let arr = this.state.OptionsPlan.filter((data, i) => {
            if (id !== i) {
                return data
            }
        })
        this.setState({
            OptionsPlan: arr
        })
    }
    addPlan = async (e) => {
        e.preventDefault()
        this.setState({
            err: false
        })
        if (!this.state.PackageFor) {

            toast.warning("Please select package for")
            return
        }
        // return
        if (!this.state.PlanName || !this.state.PlanPrice) {
            this.setState({
                err: true
            })
        }
        else {
            let data = {
                Name: this.state.PlanName,
                Price: this.state.PlanPrice,
                Options: this.state.OptionsPlan,
                PackageFor: this.state.PackageFor,
            }
            await axios.post(`${process.env.REACT_APP_API}/api/auth/adminaddplan`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("admin_token")}`
                }
            }).then((res) => {
                if (res.data.Error == false) {
                    this.getListingData()
                    this.getListingDataVenue()
                    this.setState({
                        visiblePlan: false
                    })
                    this.resetFun()
                }
                else if (res.data.token == false) {
                    localStorage.removeItem("admin_token")
                    this.props.history.push("/auth/login")
                }
            })
        }

    }
    resetFun = () => {
        this.setState({
            PlanName: "",
            PlanPrice: "",
            OptionsPlan: "",
            PackageFor: "",
        })
    }
    addOptionsEdit = (e) => {
        e.preventDefault()
        let p = this.state.Options
        let value = this.state.optionValue
        if (value.length > 0) {
            p.push(value)
            this.setState({ Options: p, optionValue: "" })
        }
    }
    removeOptionsEdit = (e, id) => {
        e.preventDefault()
        let arr = this.state.Options.filter((data, i) => {
            if (id !== i) {
                return data
            }
        })
        this.setState({
            Options: arr
        })
    }

    render() {
        console.log(this.state.plan)
        return (

            <>
                <Header />
                <div className="mt--7 container-fluid">
                    <div className="shadow card">
                        <div className="border-0 card-header d-flex align-items-center justify-content-between">
                            <h3 class="mb-0">Subscrition Plans</h3>
                            <button className="btn btn-primary" onClick={this.PlanModal}>Add Subscription Plan</button>
                        </div>
                        {this.state.loading ?
                            <>
                                <div className="card">
                                    <div className='card-header'>
                                        <h2>Vendor Package</h2>
                                    </div>
                                    <div className='card-body'>
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.records}
                                            columns={this.columns}
                                            extraButtons={this.extraButtons}
                                        />
                                    </div>
                                </div>

                                <div className="card">
                                    <div className='card-header'>
                                        <h2>Venue Package</h2>
                                    </div>
                                    <div className='card-body'>
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.recordsVenue}
                                            columns={this.columnsVenue}
                                            extraButtons={this.extraButtons}
                                        />
                                    </div>
                                </div>

                            </>
                            :
                            <div className="loader">
                                <Loader
                                    type="Puff"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                />
                            </div>
                        }
                    </div>
                </div>
                {
                    this.state.visible ?
                        <Rodal visible={this.state.visible} onClose={this.hide} width="900" height="600" >
                            <div className="header_view_popup">Edit</div>
                            <div className="main_listing_view">
                                <div className="listing_goes">
                                    <form>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="Name">Package Name</label>
                                                <input value={this.state.Name} onChange={(e) => { this.setState({ Name: e.target.value }) }} type="text" className="form-control" id="Name" />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="Price">Price</label>
                                                <input type="number" value={this.state.Price} onChange={(e) => { this.setState({ Price: e.target.value }) }} className="form-control" id="Price" />
                                            </div>
                                        </div>
                                        <label htmlFor="Options">Options</label>
                                        <div className="form-group col-md-6">
                                            <div className="parent_plus d-flex align-items-center">
                                                <input value={this.state.optionValue} onChange={(e) => { this.setState({ optionValue: e.target.value }) }} type="text" className="form-control" id="Name" />
                                                <button className="btn btn-primary ml-2" style={{ fontSize: "20px", padding: "4px 12px" }} onClick={this.addOptionsEdit} >+</button>
                                            </div>
                                        </div>
                                        {this.state.Options.map((data, i) => {
                                            return (
                                                <div className="form-group col-md-6">
                                                    <div className="parent_plus d-flex align-items-center">

                                                        <input value={data} onChange={(e) => { this.setOptions(e, i) }} type="text" className="form-control" id={i} />
                                                        <button className="btn btn-danger ml-2" style={{ fontSize: "20px", padding: "4px 12px" }} onClick={(e) => this.removeOptionsEdit(e, i)}>-</button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div style={{ cursor: "pointer", marginBottom: "20px" }} class="custom-control custom-switch">
                                            <input style={{ cursor: "pointer" }} value={this.state.delete} checked={this.state.delete} onChange={() => { this.delete(); this.setState({ delete: !this.state.delete }) }} type="checkbox" class="custom-control-input" id={`customSwitch1`} />
                                            <label style={{ cursor: "pointer" }} class="custom-control-label" htmlFor={`customSwitch1`}>Status</label>
                                        </div>

                                        <div className="submit_plan text-right">
                                            <button onClick={this.editPlan} type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Rodal>
                        :
                        ""

                }
                {
                    this.state.visiblePlan ?
                        <Rodal visible={this.state.visiblePlan} onClose={this.hidePlan.bind(this)} width="900" height="600" >
                            <div className="header_view_popup">Add Package</div>
                            <div className="main_listing_view">
                                <div className="listing_goes">
                                    <form>
                                        {this.state.err ? <p style={{ color: "red", marginBottom: "5px" }}>Please Enter All Fields</p> : ""}
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label htmlFor="PackageFor">Package For</label>
                                                <select value={this.state.PackageFor} onChange={(e) => { this.setState({ PackageFor: e.target.value }) }} type="text" className="form-control" id="PackageFor" >
                                                    <option value="">Select Package For</option>
                                                    <option value="vendor">Vendor</option>
                                                    <option value="venue">Venue</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="Name">Package Name</label>
                                                <input value={this.state.PlanName} onChange={(e) => { this.setState({ PlanName: e.target.value }) }} type="text" className="form-control" id="Name" />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="Price">Price</label>
                                                <input type="number" value={this.state.PlanPrice} onChange={(e) => { this.setState({ PlanPrice: e.target.value }) }} className="form-control" id="Price" />
                                            </div>
                                        </div>
                                        <label htmlFor="Options">Options</label>
                                        <div className="form-group col-md-6">
                                            <div className="parent_plus d-flex align-items-center">
                                                <input value={this.state.optionValue} onChange={(e) => { this.setState({ optionValue: e.target.value }) }} type="text" className="form-control" id="Name" />
                                                <button className="btn btn-primary ml-2" style={{ fontSize: "20px", padding: "4px 12px" }} onClick={this.addOptions} >+</button>
                                            </div>
                                        </div>
                                        {this.state.OptionsPlan && this.state.OptionsPlan.map((data, i) => {
                                            return (
                                                <div className="form-group col-md-6">
                                                    <div className="parent_plus d-flex align-items-center">
                                                        <input value={data} disabled type="text" className="form-control" id={i} />
                                                        <button className="btn btn-danger ml-2" style={{ fontSize: "20px", padding: "4px 12px" }} onClick={() => this.removeOptions(i)}>-</button>
                                                    </div>

                                                </div>
                                            )
                                        })}
                                        <div className="submit_plan text-right">

                                            <button onClick={this.addPlan} type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Rodal>
                        :
                        ""

                }
                {
                    this.state.DeleteModal ?
                        <Rodal visible={this.state.DeleteModal} onClose={() => this.setState({ DeleteModal: false })} width="600" height="300" >
                            <div className="header_view_popup">Delete Confirmation</div>
                            <div className="main_listing_view">
                                <div className="listing_goes mb-4">
                                    Are You Sure Want To Delete?
                                </div>
                                <button onClick={this.deleteData} type="submit" className="btn btn-primary mt-4">Submit</button>
                            </div>
                        </Rodal>
                        :
                        ""

                }
                <ToastContainer />
            </>


        )
    }
}

export default Plans;