import React, { Component } from 'react';
import Header from 'components/Headers/Header';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from "axios"
import Category from './category';

let suggestion = ["book", "mango", "apple"]

class EditBlogs extends Component {

    state = {
        blogTitle: '',
        metaPara: '',
        Category: '',
        MetaTitle: '',
        MetaKey: '',
        tags: [],
        input: '',
        tagsFilterd: [],
        cate: '',
        category: [],
        Image: '',
        id: "",
        blob: false,
        delete: false,
        cateName: "",
        cate1:""


    }
    async componentDidMount() {
        await axios.get(`${process.env.REACT_APP_API}/api/auth/getblog/${this.props.match.params.id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                let data = res.data.data[0]
                this.setState({
                    id: data._id,
                    blogTitle: data.Title,
                    metaPara: data.Meta_description,
                    Category: data.Category,
                    MetaTitle: data.Meta_title,
                    MetaKey: data.Meta_keyword,
                    tags: data.Tags,
                    description: data.Description,
                    cate: data.Category._id,
                    cateName: data.Category.Name,
                    Image: data.Blog_pic,
                    delete: data.Active

                })
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
        })
        this.getBlogCat()

    }
    getBlogCat = async () => {
        await axios.get(`${process.env.REACT_APP_API}/api/auth/getblogcategories`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                this.setState({
                    category: res.data.Data,
                })
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
        })
    }
    submitBlog = async () => {
        let { blogTitle, metaPara, MetaTitle, cate, tags, description } = this.state
        let title=""
        if(this.state.blogTitle){
          let title1 = this.state.blogTitle.toLowerCase();
           title = title1.replace(/[^a-zA-Z0-9]+/g,'-');
        }
        if (!blogTitle || !metaPara || !MetaTitle || !cate || !tags || !description) {
            alert("Please Enter All Fields")
        }
        else {
            let data = new FormData()
            data.append("id", this.state.id)
            data.append("Title", this.state.blogTitle)
            data.append("Meta_description", this.state.metaPara)
            data.append("Meta_title", this.state.MetaTitle)
            data.append("Meta_keyword", this.state.MetaKey)
            if(this.state.cate1){
                data.append("Category", this.state.cate1)
            }
            else{
              data.append("Category", this.state.cate)
            }
            data.append("Tags", JSON.stringify(this.state.tags))
            data.append("Description", this.state.description)
            data.append("Active", this.state.delete)
            data.append("Slug", title)


            if (this.state.blob) {
                data.append("Blog_pic", this.state.Image)
            }
            await axios.post(`${process.env.REACT_APP_API}/api/auth/updateblog`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("admin_token")}`
                }
            }).then((res) => {
                if (res.data.Error == false) {
                    if(res.data.msg=="Blog title already in use"){
                        alert("Blog title already in use")
                    }
                    else{
                        this.props.history.push("/admin/blogs")

                    }
                }
                else if (res.data.token == false) {
                    localStorage.removeItem("admin_token")
                    this.props.history.push("/auth/login")
                }
            })
        }

    }

    addtages = (e) => {
        // console.log(e.keyCode)
        if (e.target.value.length && e.code === "Enter" && !this.state.tags.includes(e.target.value)) {
            this.setState({ tags: [...this.state.tags, e.target.value], input: "", tagsFilterd: [] })
        } else {
            // console.log(e)
        }
    }
    delete = async () => {
        let data = {
            id: this.state.id
        }
        await axios.post(`${process.env.REACT_APP_API}/api/auth/admindeleteblogstatus`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {

            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
        })
    }
    fillterSuggestions = async (value) => {
        this.setState({ input: value })
        if (value == "") {
            this.setState({ tagsFilterd: [] })
        } else {

            await axios.post(`${process.env.REACT_APP_API}/api/auth/searchtags`, { tag: value }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("admin_token")}`
                }
            }).then((res) => {
                if (res.data.Error == false) {
                    let suggestions = res.data.tags
                    let suggestion = []
                    suggestions.map((data) => {
                        suggestion.push(data.Tags)
                    })
                    let fiterdsuggestion = suggestion.filter(data => {
                        if (!this.state.tags.includes(data)) {
                            return data
                        }

                    })
                    this.setState({ tagsFilterd: fiterdsuggestion })
                }
                else if (res.data.token == false) {
                    localStorage.removeItem("admin_token")
                    this.props.history.push("/auth/login")
                }
            })

            // console.log(fiterdsuggestion)
        }
    }

    slectTag = (value) => {
        this.setState({ tags: [...this.state.tags, value], input: "", tagsFilterd: [] })
    }

    removeTags = (index) => {
        let neObj = this.state.tags
        if (index > -1) {
            neObj.splice(index, 1);
        }
        this.setState({ tags: [...neObj] })
    }

    handleCkEditorDescription = (event, editor) => {
        const data = editor.getData();
        this.setState({
            description: data
        })
        // console.log(data);
    }

    render() {
        return (
            <>
                <Header />
                <div className="mt--7 container-fluid">
                    <div className="shadow card">
                        <div className="border-0 card-header">
                            <h3 className="mb-0">Edit Blog</h3>
                        </div>
                        <div className="main_blogs">
                            <div className="list_blog">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-control-label" htmlFor="input-city">Blog Title</label>
                                            <input id="input-city" placeholder="" value={this.state.blogTitle} onChange={(e) => this.setState({ blogTitle: e.target.value })} type="text" className="form-control-alternative form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-control-label">Category</label>
                                            <select className="form-control-alternative form-control" onChange={(e) =>{this.setState({cate1:e.target.value})}}>
                                                <option value={this.state.cate} >{this.state.cateName}</option>
                                                {this.state.category.map((data) => {
                                                    if (data._id != this.state.cate)
                                                        return (
                                                            <option value={data._id}>{data.Name}</option>
                                                        )
                                                })}

                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-control-label" htmlFor="input-city">Meta Title</label>
                                            <input value={this.state.MetaTitle} id="input-city" placeholder="" type="text" onChange={(e) => this.setState({ MetaTitle: e.target.value })} className="form-control-alternative form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-control-label">Meta Keyword</label>
                                            <input placeholder="" value={this.state.MetaKey} type="text" onChange={(e) => this.setState({ MetaKey: e.target.value })} className="form-control-alternative form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-control-label">Tags</label>
                                            <div className='border setting_tags p-2 form-control-alternative form-control'>
                                                {this.state.tags && this.state.tags.map((data, index) => {
                                                    return (
                                                        <span className="alert alert-success mx-1 p-1" >
                                                            {data}  <span className=" remove_tags mx-1"
                                                                onClick={() => { this.removeTags(index) }}
                                                            >x</span>
                                                        </span>
                                                    )
                                                })}
                                                <div className='tagesinput'>
                                                    <input type="text"
                                                        className='border-0'
                                                        placeholder='add tages...'
                                                        value={this.state.input}
                                                        onKeyDown={(e) => this.addtages(e)}
                                                        onChange={(e) => { this.fillterSuggestions(e.target.value) }}
                                                    />
                                                    {this.state.tagsFilterd.length > 0 && (<div>
                                                        {this.state.tagsFilterd.map((data, index) => (
                                                            <span
                                                                onClick={() => this.slectTag(data)}
                                                            >{data}</span>
                                                        ))}
                                                        {/* <span></span> */}
                                                    </div>)}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-control-label" htmlFor="input-city">Meta Description</label>
                                            <textarea value={this.state.metaPara} className="form-control-alternative form-control" type="text" onChange={(t) => this.setState({ metaPara: t.target.value })}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-control-label" htmlFor="input-city">Image</label>
                                            <input type="file" className="form-control-alternative form-control" onChange={(e) => this.setState({ blob: true, blobImage: URL.createObjectURL(e.target.files[0]), Image: e.target.files[0] })} />
                                        </div>
                                    </div>
                                    {
                                        this.state.blob
                                            ?
                                            <img className="mb-3" width="250px" src={this.state.blobImage} />
                                            :
                                            <img className="mb-3" width="250px" src={`${process.env.REACT_APP_API}/${this.state.Image}`} />

                                    }
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-control-label" htmlFor="input-city">Blog Description</label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={{ removePlugins: ['CKFinderUploadAdapter', 'CKFinder', 'EasyImage', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed'], }}
                                                data={this.state.description}
                                                onInit={(editor) => { }}
                                                onChange={this.handleCkEditorDescription}
                                            />
                                            {/* <textarea className="form-control-alternative form-control" type="text" onChange={(t) => this.setState({blogPara:t.target.value})}></textarea> */}
                                        </div>
                                    </div>
                                    <div style={{ cursor: "pointer", marginBottom: "20px" }} class="custom-control custom-switch">
                                        <input style={{ cursor: "pointer" }} value={this.state.delete} checked={this.state.delete} onChange={() => { this.delete(); this.setState({ delete: !this.state.delete }) }} type="checkbox" class="custom-control-input" id={`customSwitch1`} />
                                        <label style={{ cursor: "pointer" }} class="custom-control-label" htmlFor={`customSwitch1`}>Status</label>
                                    </div>

                                </div>
                            </div>
                            <div className="submit_blog">
                                <button className='btn btn-primary' onClick={this.submitBlog}>submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default EditBlogs;