/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import axios from 'axios'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import { Link } from "react-router-dom";

const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [loading, setloading] = useState(false);
  const [listing, setlisting] = useState([]);
  const [chartExample1Data, setChartExample1Data] = useState("data1");

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };
  useEffect(() => {
    if (!localStorage.getItem("admin_token")) {
      props.history.push("/auth/login")
    }
    else {
      getListingData()
    }
  }, [])
  const getListingData = async () => {

    setloading(false)

    await axios.get(`${process.env.REACT_APP_API}/api/auth/admingetactivatedlisting`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admin_token")}`
      }
    }).then((res) => {
      if (res.data.Error == false) {
        let a = res.data.data.sort((a, b) => b.Views.length - a.Views.length).slice(0, 10)
        setlisting(a)

      }
      else if (res.data.token == false) {
        localStorage.removeItem("admin_token")
        props.history.push("/auth/login")
      }
      setloading(true)
    })
  }
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Top Listings</h3>
                  </div>
                  <div className="col text-right">
                    <Link
                      color="primary"
                      size="sm" to="/admin/listings"
                      className="btn btn-sm btn-primary"
                    >
                      See all
                    </Link>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                {loading ? <>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Listing</th>
                      <th scope="col">Views</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listing && listing.map((data) => {
                      return (
                        <tr>
                          <th scope="row">{data.Name}</th>
                          <td>
                            {data.Views.length}
                          </td>
                        </tr>
                      )
                    })}

                  </tbody>
                </>
                  :
                  <div className="loader">
                    <Loader
                      type="Puff"
                      color="#00BFFF"
                      height={100}
                      width={100}
                    />
                  </div>
                }

              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
